<template>
    <b-card class="blog-edit-wrapper">
      <!-- form -->
      <div class="text-center">
        <h4 class="mt-5">
   Update Image
  
         <feather-icon icon="Edit2Icon" />
        </h4>
        
        
      </div>
      <validation-observer ref="updateFormvalidate">
       <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <div class="border rounded p-2">
                <h4 class="mb-1">Image</h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row">
                  <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE1"
                      :src="`https://api.lmitac.com/${updateForm.image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refPreviewE1.src = Tabimage
                  updateForm.image=''
                  ">
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1"> </b-card-text>
                <div class="d-inline-block  mt-1">
                    <b-form-group
                        label=" Image "
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                     <validation-provider
                    #default="{ errors }"
                    name="Tab Image "
                    rules="required"
                  >
                      <b-form-file
                        ref="refInputE1"
                        v-model="updateForm.image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRenderer"
                      />
                       <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
                      <b-form-group
                        label="Tab Image Alt Text"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="alt-image"
                          v-model="updateForm.alt_image"
                        />
                      </b-form-group>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
              
            </b-col>
            <b-col md="6">
              <b-form-group
                label="In Gallery Order"
                label-for="blog-edit-title"
                class="mb-2"
              >
               <validation-provider
                    #default="{ errors }"
                    name="Gallery Order"
                    rules="required"
                  >
                <b-form-input id="blog-edit-title" v-model="updateForm.order" />
                   <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
           
            <b-col md="6">
              <b-form-group
                label="In Slider Order
  "
                label-for="blog-edit-slug"
                class="mb-2"
              >
              
                 <validation-provider
                    #default="{ errors }"
                    name="In Slider Order"
                    rules="required"
                  >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="updateForm.slider_order"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          
           
          
            <b-col cols="12" class="mt-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="save"
              >
                Save Changes
              </b-button>
              
            </b-col>
          </b-row>
        </b-form>
  
      </validation-observer>
     <gallery-list ref="table"/>
      <!--/ form -->
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BMedia,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from "vue-select";
  import { quillEditor } from "vue-quill-editor";
  import { required, email } from "@validations";
  import Ripple from "vue-ripple-directive";
  import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
  import { ref, reactive, computed } from "@vue/composition-api";
  import store from "@/store";
  import { useRouter } from "@core/utils/utils";
  import Vue from "vue";
  
  export default {
    setup(props) {
      const refInputEl = ref(null);
      const refPreviewEl = ref(null);
      const Tabimage = ref("");
      const { route } = useRouter();
      const id = route.value.params.id;
      //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
      console.log(id);
      store.dispatch("gallery/Getimage", { id }).then((response) => {
        store.commit("gallery/GET_IMAGE", response?.data.data);
      });
      const categoriesList = ref([]);
     
  
      const Form = computed(() => store.state.gallery.image);
      const updateForm = reactive(Form);
      console.log("CourseForm", Form);
      const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        (base64) => {
          refPreviewEl.value.src = base64;
        }
      );
      const updateFormvalidate = ref();
  
      const save = () => {
        const formData = new FormData();
  
        console.log(updateForm);
    
        formData.append("image", updateForm.value.image);
formData.append("alt_image", updateForm.value.alt_image);
formData.append("order", updateForm.value.order);
formData.append("slider_order", updateForm.value.slider_order);

        formData.append("_method", "put");
  
        //  updateFormvalidate.value.validate().then(success => {
        //         if (success) {
        store
          .dispatch("gallery/Updateimage", { id, formData })
          .then((response) => {
            Vue.swal({
              icon: "success",
              title: "Updated!",
  
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        // }})
      };
      return {
        refInputEl,
        refPreviewEl,
        Tabimage,
        updateForm,
        inputImageRenderer,
        updateFormvalidate,
        id,
        Form,
  
        save,
        required,
        email,
        categoriesList,
      };
    },
  
    components: {
      BCard,
      BMedia,
      ValidationProvider,
      ValidationObserver,
      BCardText,
      BMediaAside,
      BMediaBody,
      BFormRadio,
      BFormCheckbox,
      BFormRadioGroup,
      BForm,
      BLink,
      BImg,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BFormFile,
      vSelect,
      quillEditor,
    },
  
    directives: {
      Ripple,
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
  </style>
  